<template>
	<div class="star-animate">
		<!-- <img class="earth" src="~@/assets/images/index/earth.png" /> -->
		<div class="out_circle">
			<div class="nav_circle r1">
				<div class="img_top img">
					<img src="~@/assets/images/test/moon.png" />
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	export default {
		name: 'test2',
		data() {
			return {
				width: window.innerWidth,
				height: window.innerHeight,
				pixelRatio: window.pixelRatio,
			}
		},
		mounted() {},
		destroyed() {},
		methods: {}
	}
</script>

<style lang="scss" scoped>
	.star-animate {
		width: 100%;
		height: 100%;
		background-color: #000;
		
		position: relative;
	}
	
	.earth {
		position: absolute;
		left: 0;
		top: 64%;
		width: 100%;
	
		object-fit: contain;
	}

	.out_circle {
		position: absolute;
		width: 150%;
		height: 2880px;
		left: -25%;
		top: -35%;
		
		border-radius: 50%;
		
		transform-style: preserve-3d;
		-webkit-transform-style: preserve-3d;
		perspective: 20000;
		-webkit-perspective: 20000;
		
		transform: rotateX(66deg);
		-webkit-transform: rotateX(66deg);
		/* Safari and Chrome */
	}

	.nav_circle {
		width: 100%;
		height: 100%;
		float: left;
		position: absolute;
		top: 0;
		left: 0;
		text-align: center;
		transform-style: preserve-3d;
		-webkit-transform-style: preserve-3d;
	}

	.r1 {
		animation: rotate 13s linear infinite;
		-webkit-animation: rotate 13s linear infinite;
		-moz-animation: rotate 13s linear infinite;
		-o-animation: rotate 13s linear infinite;
	}

	.r1 .img_top {
		animation: rotate_c1 13s linear infinite;
		-webkit-animation: rotate_c1 13s linear infinite;
	}

	.img_top img {
		width: 690px;
		height: 690px;
	}

	.img_top {
		position: absolute;
		z-index: 1;
		transform: rotateX(70deg);
		-ms-transform: rotateX(70deg);
	}


	@keyframes rotate {
		0% {
			transform: rotate(0deg) skew(0deg) scale(1);
			-ms-transform: rotate(0deg) skew(0deg) scale(1);
			-moz-transform: rotate(0deg) skew(0deg) scale(1);
			-webkit-transform: rotate(0deg) skew(0deg) scale(1);
			-o-transform: rotate(0deg) skew(0deg) scale(1);
		}

		100% {
			transform: rotate(360deg) skew(0deg) scale(1);
			-ms-transform: rotate(360deg) skew(0deg) scale(1);
			-moz-transform: rotate(360deg) skew(0deg) scale(1);
			-webkit-transform: rotate(360deg) skew(0deg) scale(1);
			-o-transform: rotate(360deg) skew(0deg) scale(1);
		}
	}

	@keyframes rotate_c1 {
		0% {
			transform: rotateX(-70deg) rotateY(0deg) skew(0deg) scale(0.75);
			-ms-transform: rotateX(-70deg) rotateY(0deg) skew(0deg) scale(0.75);
			-moz-transform: rotateX(-70deg) rotateY(0deg) skew(0deg) scale(0.75);
			-webkit-transform: rotateX(-70deg) rotateY(0deg) skew(0deg) scale(0.75);
			-o-transform: rotateX(-70deg) rotateY(0deg) skew(0deg) scale(0.75);
		}

		12% {
			transform: rotateX(-70deg) rotateY(40deg) skew(0deg) scale(0.5);
			-ms-transform: rotateX(-70deg) rotateY(40deg) skew(0deg) scale(0.5);
			-moz-transform: rotateX(-70deg) rotateY(40deg) skew(0deg) scale(0.5);
			-webkit-transform: rotateX(-70deg) rotateY(40deg) skew(0deg) scale(0.5);
			-o-transform: rotateX(-70deg) rotateY(40deg) skew(0deg) scale(0.5);
		}

		62% {
			transform: rotateX(-70deg) rotateY(225deg) skew(0deg) scale(0.25);
			-ms-transform: rotateX(-70deg) rotateY(225deg) skew(0deg) scale(0.25);
			-moz-transform: rotateX(-70deg) rotateY(225deg) skew(0deg) scale(0.25);
			-webkit-transform: rotateX(-70deg) rotateY(225deg) skew(0deg) scale(0.25);
			-o-transform: rotateX(-70deg) rotateY(225deg) skew(0deg) scale(0.25);
		}

		100% {
			transform: rotateX(-70deg) rotateY(360deg) skew(0deg) scale(0.5);
			-ms-transform: rotateX(-70deg) rotateY(360deg) skew(0deg) scale(0.5);
			-moz-transform: rotateX(-70deg) rotateY(360deg) skew(0deg) scale(0.5);
			-webkit-transform: rotateX(-70deg) rotateY(360deg) skew(0deg) scale(0.5);
			-o-transform: rotateX(-70deg) rotateY(360deg) skew(0deg) scale(0.5);
		}
	}
</style>
